import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"
import ImageDownloadSocial from "../../../components/ImageDownloadSocial"



const IndexPage = () => {
    const root = "/img/download/my-success-story"

    return (
        <Layout pageTitle="My Success Story - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/members/my-success-story">Back</Link>
                </Col>
            </Row>

           
                
            <ImageDownload
            root ="/img/download/my-success-story"
            filename="mss-general-promo"
            name="My Success Story General Promo"
            text="Use this to promote the My Success Story membership! You can use copy from the Brand Message document below, pay particular attention to the My Success Story section."
            text2=""
            squareHref="https://www.canva.com/design/DAFqfV0ApQU/XLF1SOU-uNHNZQ_4zJS-1Q/view?utm_content=DAFqfV0ApQU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAFqfNvf_bk/99eQF8C3T_m9khZKbRj53g/view?utm_content=DAFqfNvf_bk&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1N17wids4-a-HimzOmu_KfNRug_ea1WvzupO_9SvW3Uk/edit?usp=sharing"
            />  

<ImageDownload
            root ="/img/download/my-success-story"
            filename="mss-general-promo-2"
            name="My Success Story General Promo 2"
            text="Use this to promote the My Success Story membership! You can use copy from the Brand Message document below, pay particular attention to the My Success Story section."
            text2=""
            squareHref="https://www.canva.com/design/DAFqfgNKRgM/fXKUWel_l1NdmZId3aWMuw/view?utm_content=DAFqfgNKRgM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            horizontalHref="https://www.canva.com/design/DAEkWqxj1_0/5bqWEAnAe4P9whdh4dXsaw/view?utm_content=DAEkWqxj1_0&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
            copyHref="https://docs.google.com/document/d/1N17wids4-a-HimzOmu_KfNRug_ea1WvzupO_9SvW3Uk/edit?usp=sharing"
            />  
        

            
        </Layout>

        
    )
}

export default IndexPage
